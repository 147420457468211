import React, {
  useCallback,
  useState,
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  Root,
  TextBox,
  EndInputAdornmentIconButton,
  SearchSuggestionsDiv,
  SearchItemsDiv,
  SearchBoxDiv,
  SearchForm,
} from './searchBox.styles';
import {
  searchBarQuery,
  SearchBarQueryResponse,
} from '@queries/search/queries';
import { useApolloClient } from '@apollo/client';
import { useRouter } from 'next/router';
import { useOpenModal } from '@hooks/useModalOpenState';
import { useWindowSize } from '@hooks/useWindowSize';
import { getRoute } from '@utils';
import { SearchBackdrop } from '@screens/search/searchBackdrop';
import clsx from 'clsx';
import { SearchElementItem } from './searchElementItem';
import { useScroll } from '@hooks/useScroll';
import { useFocusSearchBox } from '@hooks/useFocusSearchBox';
import { Console } from '@utils';
import { capitalizeWord } from '@utils/strings';
import { getSuggestions } from '@services/meiliSearch';
import { CancelButton } from './Header.styles';

interface SearchBoxProps {
  autoFocusSearchBox?: boolean;
  className?: string;
  isHeader: boolean;
  isVisible: boolean;
  setIsVisible?: (isVisible: boolean) => void;
  searchRef?: React.RefObject<HTMLDivElement>;
}

export function SearchBox(props: SearchBoxProps) {
  const client = useApolloClient();
  const { autoFocusSearchBox, isHeader, isVisible, searchRef, setIsVisible } =
    props;
  const size = useWindowSize();
  const desktop = useMemo(() => {
    if (!size.width) return true;
    return size.width >= 768;
  }, [size.width]);

  const router = useRouter();
  const openModal = useOpenModal();
  const { push } = router;
  const [showResults, setShowResults] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState('');
  const [topSearchResults, setTopSearchResults] = useState<string[]>();
  const [historySearchResults, setHistorySearchResults] = useState<string[]>();
  const [suggestedResults, setSuggestedResults] = useState<string[]>();

  const scrollStatus = useScroll();
  const focusSearchBox = useFocusSearchBox();
  const search = useMemo(() => {
    if (router.query.q) {
      return decodeURIComponent(router.query.q as string);
    }
    return '';
  }, [router.query.q]);
  const inputRef = useRef<HTMLInputElement>(undefined);

  const searchInputQuery = useCallback(
    async (input: string) => {
      setLoading(true);
      if (input.length === 0) {
        const response = await client.query<SearchBarQueryResponse>({
          query: searchBarQuery('searchBar'),
          variables: { params: { query: '' } },
          fetchPolicy: 'network-only',
        });
        setTopSearchResults(
          response.data.productSuggestions
            .filter((suggestion) => suggestion.type === 'top')
            .map((suggestion) => suggestion.text)
            .slice(0, 3),
        );
        setHistorySearchResults(
          response.data.productSuggestions
            .filter((suggestion) => suggestion.type === 'history')
            .map((suggestion) => suggestion.text)
            .slice(0, 3),
        );
      }
      if (input.length > 0) {
        const suggestedResults = await getSuggestions(input);
        setSuggestedResults(suggestedResults.hits.map((s) => s.text));
      }

      setLoading(false);
      setShowResults(true);
    },
    [client, setShowResults],
  );

  useEffect(() => {
    Console.log({ msg: '[SearchHeader] search prop change', search });
    if (search) {
      setSearchInput(search);
    }
  }, [search, setSearchInput]);

  useEffect(() => {
    void searchInputQuery(searchInput);
  }, [searchInput, searchInputQuery]);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      e.stopPropagation();
      const trimmedSearch = searchInput.trim();
      if (trimmedSearch.length > 0) {
        void push(getRoute('search', { q: encodeURI(trimmedSearch) }));
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        setSearchInput('');
      }
      setShowResults(false);
      focusSearchBox.onblur();
      scrollStatus.allowScroll('submit');
    },
    [searchInput, push, scrollStatus, focusSearchBox],
  );

  const handleResultClick = (newQuery: string) => {
    void push(getRoute('search', { q: encodeURI(newQuery) }));
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setSearchInput(newQuery);
    setShowResults(false);
    focusSearchBox.onblur();
    scrollStatus.allowScroll('result');
  };

  const handleWrite = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = String(e.target.value).replace('@', ' ');
      setSearchInput(newValue);
      router.query.q = encodeURI(newValue);
    },
    [setSearchInput, router.query],
  );

  const handleFocus = useCallback(() => {
    setShowResults(true);
    focusSearchBox.onfocus();
    scrollStatus.disableScroll('searchFocus');
  }, [desktop, scrollStatus, focusSearchBox]);

  const handleOutFocus = useCallback(
    (tag: string) => {
      focusSearchBox.onblur();
      if (!openModal.isModalOpen) {
        scrollStatus.allowScroll('blur: ' + tag);
      }
    },
    [focusSearchBox, openModal.isModalOpen, scrollStatus],
  );

  const handleClean = useCallback(() => {
    handleWrite({
      target: {
        value: '',
      },
    } as unknown as ChangeEvent<HTMLInputElement>);
  }, [handleWrite]);

  useEffect(() => {
    if (!focusSearchBox.isFocus) {
      handleOutFocus('isFocus effect');
      inputRef.current?.blur();
      return;
    }
    if (focusSearchBox.isFocus && isVisible) {
      inputRef.current?.focus();
      handleFocus();
    }
  }, [focusSearchBox.isFocus, handleOutFocus, isVisible]);

  useEffect(() => {
    if (router.asPath.includes('producto')) {
      setSearchInput('');
    }
  }, [router.asPath]);

  const handleClose = useCallback(() => {
    if (setIsVisible) {
      setIsVisible(isVisible && !isHeader);
    }
    focusSearchBox.onblur();
  }, [focusSearchBox, setIsVisible, isHeader, isVisible]);

  return (
    <>
      <Root
        className={props.className}
        ref={searchRef}
        isFocus={focusSearchBox.isFocus}
        isHeader={isHeader}
        isVisible={isVisible}
      >
        <SearchBoxDiv isHeader={isHeader} isFocus={focusSearchBox.isFocus}>
          <SearchForm
            onSubmit={handleSubmit}
            onFocus={handleFocus}
            noValidate
            isFocus={focusSearchBox.isFocus}
          >
            <TextBox
              variant="outlined"
              placeholder="Busca en Farmacia Meki"
              inputRef={inputRef}
              autoFocus={autoFocusSearchBox || false}
              value={searchInput}
              isFocus={focusSearchBox.isFocus}
              isHeader={isHeader}
              isVisible={isVisible}
              onChange={handleWrite}
              type="search"
              inputProps={{
                'aria-autocomplete': 'both',
                'aria-haspopup': 'false',
                autoCapitalize: 'off',
                autoComplete: 'off',
                autoCorrect: 'off',
                role: 'combobox',
                title: 'Buscar',
                'aria-label': 'Buscar',
                type: 'search',
                spellCheck: false,
                enterKeyHint: 'search',
              }}
              InputProps={{
                endAdornment:
                  searchInput && (desktop || focusSearchBox.isFocus) ? (
                    <InputAdornment position="end">
                      <EndInputAdornmentIconButton
                        aria-label="clear search"
                        onClick={handleClean}
                      >
                        <CloseRoundedIcon fontSize="inherit" />
                      </EndInputAdornmentIconButton>
                    </InputAdornment>
                  ) : null,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            {!desktop && focusSearchBox.isFocus ? (
              <CancelButton onClick={handleClose}>Cancelar</CancelButton>
            ) : null}
          </SearchForm>
          <SearchSuggestionsDiv isFocus={focusSearchBox.isFocus}>
            {showResults && focusSearchBox.isFocus && (
              <SearchItemsDiv
                className={clsx('mobile-min-100dvh', 'fix-mobile-min-dvh')}
              >
                {showResults &&
                  searchInput &&
                  suggestedResults?.length == 0 && (
                    <SearchElementItem
                      value={capitalizeWord(searchInput)}
                      onClick={() =>
                        handleResultClick(capitalizeWord(searchInput))
                      }
                      index={0}
                    />
                  )}
                {showResults && searchInput.length == 0 && (
                  <>
                    {loading && (
                      <>
                        <SearchElementItem value={searchInput} loading />
                        <SearchElementItem value={searchInput} loading />
                        <SearchElementItem value={searchInput} loading />
                      </>
                    )}
                    {!loading && historySearchResults?.length > 0 && (
                      <>
                        {historySearchResults.map((result, i) => (
                          <SearchElementItem
                            key={i}
                            value={result}
                            onClick={() => handleResultClick(result)}
                            isRecent
                            index={i}
                          />
                        ))}
                      </>
                    )}
                    {!loading && topSearchResults?.length > 0 && (
                      <>
                        {topSearchResults.map((result, i) => (
                          <SearchElementItem
                            key={i}
                            value={result}
                            onClick={() => handleResultClick(result)}
                          />
                        ))}
                      </>
                    )}
                  </>
                )}
                {showResults &&
                  searchInput &&
                  (!loading || suggestedResults?.length > 0) &&
                  (suggestedResults?.map((result, i) => (
                    <SearchElementItem
                      key={i}
                      index={i}
                      value={result}
                      onClick={() => handleResultClick(result)}
                    />
                  )) ??
                    [])}
                {desktop
                  ? loading &&
                    Boolean(searchInput) &&
                    suggestedResults?.length == 0 && (
                      <>
                        <SearchElementItem value={searchInput} loading />
                        <SearchElementItem value={searchInput} loading />
                        <SearchElementItem value={searchInput} loading />
                      </>
                    )
                  : !!searchInput &&
                    showResults &&
                    loading && (
                      <>
                        <SearchElementItem value={searchInput} loading />
                        <SearchElementItem value={searchInput} loading />
                        <SearchElementItem value={searchInput} loading />
                      </>
                    )}
              </SearchItemsDiv>
            )}
          </SearchSuggestionsDiv>
        </SearchBoxDiv>
      </Root>
      <SearchBackdrop
        handleToggleClick={() => desktop && handleOutFocus('backdrop')}
        trasparent={false}
        isFocus={focusSearchBox.isFocus}
      />
    </>
  );
}
